import {
  ListItem,
  Button,
  List,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { CaretDown } from '@phosphor-icons/react';
import { useAppdata, useTranslation } from '../../lib/hooks.context';
import { SiteLocale } from '../../types';
import Link from '../Link';

const LangSelector = ({ onNavigate }: { onNavigate?: () => void }) => {
  const { pathname, query, locale = 'fi' } = useRouter();
  const { translations } = useAppdata();
  const t = useTranslation();

  // TODO: It was requested that <select> would be used, but then there wont be links anymore. From semantical pov this should still be just a style fix

  const translatedLocales = Object.keys(translations || {});
  const entries = Object.entries(translations || {});

  return (
    <Popover placement="bottom-end" matchWidth>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              variant="transparent"
              textStyle="navItem.globalLink"
              rightIcon={<CaretDown />}
            >
              {t(locale)}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            border="none"
            width="auto"
            minWidth="10rem"
            as={List}
            variant="searchResults"
          >
            {translatedLocales.includes(locale) ? null : (
              <ListItem>
                <Link
                  href={{
                    pathname,
                    query,
                  }}
                  aria-current="page"
                >
                  {t(locale)}
                </Link>
              </ListItem>
            )}
            {entries.map(([lang, permalink]) => (
              <ListItem key={lang}>
                <Link
                  locale={lang as SiteLocale}
                  href={permalink}
                  textStyle="navItem.globalLink"
                  onClick={() => {
                    onClose();
                    if (onNavigate) {
                      onNavigate();
                    }
                  }}
                >
                  {t(lang)}
                </Link>
              </ListItem>
            ))}
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default LangSelector;
