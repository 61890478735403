import { ListItem } from '@chakra-ui/react';
import { CircleWavyCheck, SignIn } from '@phosphor-icons/react';
import { useAppdata } from '../../lib/hooks.context';
import { MhyChakraProps } from '../../types';
import Link from '../Link';
import LangSelector from './LangSelector';

const HeaderLinks = ({
  onNavigate,
  ...props
}: MhyChakraProps & { onNavigate?: () => void }) => {
  const { navigations } = useAppdata();
  const headerLinks = navigations?.header_links;

  return (
    <>
      {headerLinks?.membership && (
        <ListItem {...props}>
          <Link
            href={headerLinks?.membership.url}
            textStyle="navItem.globalLink"
            onClick={onNavigate}
            variant="iconLink"
          >
            <CircleWavyCheck />
            {headerLinks?.membership.title}
          </Link>
        </ListItem>
      )}
      {headerLinks?.login && (
        <ListItem {...props}>
          <Link href={headerLinks?.login.url} variant="iconLink">
            <SignIn />
            {headerLinks?.login.title}
          </Link>
        </ListItem>
      )}
      <ListItem {...props}>
        <LangSelector onNavigate={onNavigate} />
      </ListItem>
    </>
  );
};

export default HeaderLinks;
